import Vue from 'vue'
import 'lib-flexible'
import App from './App.vue'
import router from './router'
import store from './store'
import Vant from 'vant';
import 'vant/lib/index.css';
import VueI18n from "vue-i18n";
import axios from 'axios';
import Qs from 'qs'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import VueClipboard from 'vue-clipboard2'
import './plugins/rem'
import md5 from 'js-md5';
import VueResizeText from 'vue-resize-text';
import './store/shax'
import sha1 from "sha1";

Vue.use(VueI18n)
const i18n = new VueI18n({
    locale: store.getters.language,    // 语言标识
    //this.$i18n.locale // 通过切换locale的值来实现语言切换
    messages: {
      'zh-CN': require('./lang/zh'),   // 中文语言包
      'en-US': require('./lang/en')    // 英文语言包
    }
})
Vue.use(VueResizeText)
VueClipboard.config.autoSetContainer = true
Vue.use(VueClipboard)
Vue.use(Vant);

Vue.config.productionTip = false
Vue.prototype.$axios = axios;
Vue.prototype.qs = Qs;
Vue.prototype.$post = post;
Vue.prototype.$get = get;
Vue.prototype.$md5 = md5;
Vue.prototype.$getw=getw;
Vue.prototype.$getN=getN;

let development=process.env.NODE_ENV == 'development' ? true : false;
 var  root = "";
function url(a){
	var url = root+a;
	return url
}
NProgress.configure({     
  easing: 'ease',  // 动画方式    
  speed: 500,  // 递增进度条的速度    
  showSpinner: false, // 是否显示加载ico    
  trickleSpeed: 200, // 自动递增间隔    
  minimum: 0.3 // 初始化时的最小百分比
})

router.beforeEach((to, from , next) => {
  // 每次切换页面时，调用进度条
  NProgress.start();
  next();
});



router.afterEach(() => {  
  // 在即将进入新的页面组件前，关闭掉进度条
  NProgress.done()
})
// function msd(e) {
//   let data = parseInt(new Date().getTime() / 1000) 
//   var h = md5(e + data + 'filplaywebcom')
//   return h
// }

function post(obj){
    root="/api/"
  let postData = obj.data;
  let api=url(obj.url);
  return new Promise((resolve, reject) => {
    axios.post(api,postData).then(res => {
      if (res.data) {
          obj.callback(res.data);
      }
  }).catch(err => {
      reject(err)
  });
})
}
function get(obj){
    root="/api/"  
  let postData = obj.data;
  let api=url(obj.url);
  return new Promise((resolve, reject) => {
    axios.get(api,{params:postData}).then(res => {
      if (res.data) {
          obj.callback(res.data);
      }
  }).catch(err => {
      reject(err)
  });
})
}
function getw(obj){
  root="/v1api"  
let api=url(obj.url);
return new Promise((resolve, reject) => {
  axios.get(api,{headers:{
  'x-auth': 'acd92fb8009a5d5846a088819b03428b1652974942059334681'}}).then(res => {
    if (res.data) {
      store.commit("usdt", res.data.data);
        obj.callback(res.data);
    }
}).catch(err => {
    reject(err)
});
})
}

function getN(obj){
let sign= getNowFormatDate()+'U2FsdGVkX1/Z3tHLKEMexUf6DlKUyrvbTndJHyLX3qA'
// http://154.23.187.73/?action=sign
let api=development?'http://154.23.187.73/?action=sign':'/sign';
let postData=new FormData()
postData.append('sign',sha1(sign))
return new Promise((resolve, reject) => {
  axios.post(api,postData).then(res => {
    if (res.data) {
      store.commit("nlist",JSON.stringify(res.data.data.reverse()));
        // obj.callback(res.data);
    }
}).catch(err => {
    reject(err)
});
})
}
function getNowFormatDate() {
  var date = new Date();
  var seperator1 = "-";
  var year = date.getFullYear();
  var month = date.getMonth() + 1;
  var strDate = date.getDate();
  if (month >= 1 && month <= 9) {
    month = "0" + month;
  }
  if (strDate >= 0 && strDate <= 9) {
    strDate = "0" + strDate;
  }
  var currentdate = year + seperator1 + month + seperator1 + strDate;
  return currentdate;
}

// function getw(){
//   let api='/v1api/v2/tokens/0x4c0824f7e318c26646dae05b44634d839993e5c4-bsc/extraDetail';
//   return new Promise((resolve, reject) => {
//     axios.get(api,{headers:{'authorization': 'aace37f108b77bee312e462f34342190e12425701651331327787',
// 'x-auth': 'a79902e0cb4d50e9fe0c76382d65ad161651331077968479654'}}).then(res => {
//       if (res) {
//         // console.log(res.data);
//         // store.commit("usdt", res.data.data);
//           obj.callback(res.data);
//       }
//   }).catch(err => {
//       reject(err)
//   });
// })
// }

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
